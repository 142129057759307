@import "variables";
@import "fonts";

html {
  position: relative;
  width: 100%;
}

// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     overflow-x: hidden;
// }

body {
  position: relative !important;
  font-family: var(--book);
  font-style: normal;
  font-size: 15px;
//   background: #F4FFFC;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.main-artical {
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
.btn {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
}

p {
  font-size: 15px;
  font-family: var(--regular);
  color: var(--themeColor);
}

input,
textarea {
  color: var(--white);
}

*::-moz-selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;
}

*::-moz-selection {
  background: var(--themeColor) none repeat scroll 0 0;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: var(--themeColor);
  color: #fff;
  text-shadow: none;
}

.themeColor {
  color: var(--themeColor);
}

.darkColor {
  color: var(--darkColor);
}

.whiteColor {
  color: var(--white);
}

.bg-white {
  background: var(--white);
}

.bg-themeColor {
  background: var(--themeColor);
}

.bg-darkColor {
  background: var(--darkColor);
}

/* header-btn */
.header-btn {
  overflow: hidden;
}

.header-btn::after {
  height: 100%;
  left: -35% !important;
  top: 0 !important;
  transform: skew(50deg);
  transition-duration: 0.6s !important;
  transform-origin: top left;
  width: 0;
}

.header-btn:hover:after {
  height: 100%;
  width: 135%;
}

.common-space {
  padding-top: 50px;
  padding-bottom: 50px;
}

.common-space-over {
  margin-top: 100px;
  margin-bottom: 100px;
}

.common-container {
  padding: 10rem 0 5rem 0;
}

.banner {
  position: relative;
  background-image: url("../images/png/bannerBgEn.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}


.container-fluid {
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.sectionsBg{
   background-image: url("../images/png/sectionsBg2.png");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: bottom; 
}
.secComBg{
    mix-blend-mode: exclusion;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.baseLayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  z-index: -1;
//   background: var(--darkGradientBlend);
  background: var(--primaryBg);
  
  pointer-events: none;
  // overflow-x: hidden;
}

.headerBtn span {
  white-space: nowrap;
}

.banner {
  z-index: 10;
}

.aboutFluid {
  z-index: 20;
  position: relative;
}
.aboutFluid:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 180px;
    background: linear-gradient(90deg, rgba(223, 253, 244, 1) 0%, rgba(135, 230, 208, 1) 100%);
    display: none;
}
.expertiseFluid {
  z-index: 18;
}

.baseLayerAbout {
  // top: -150px;
  top: 0;
//   background: var(--darkGradientBlend2);
  background: #DEFFF6;

}

.baseLayerExpertise {
  top: -80px;
}

.aboutFluid {
  padding-top: 80px;
  padding-bottom: 200px;
  // margin-bottom: 150px;
}

.cmn-text-card {
  align-items: center;
}

.main-heading {
  margin-bottom: 23px;
}

.baseLayerExpertise {
  background: var(--darkGradientBlendReverse);
}

.baseLayerTrainers {
  top: -90px;
}

.slick-dots {
  bottom: -55px;
}

.slick-dots li {
  width: 15px;
  height: 15px;
  margin: 0 4px;
}

.slick-dots li button {
  padding: 0;
  width: 15px;
  height: 15px;
}

.slick-dots li button::before {
  font-size: 0;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  content: "";
  opacity: 1;
  border: 1px solid var(--text);
  background: var(--text);
}

.slick-dots li.slick-active button::before {
  background: var(--themeColor);
  border: 1px solid var(--themeColor);
  box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, 0.2);
}

.swiper {
  overflow: visible !important;
}
.slick-prev::before, .slick-next::before {
  color: var(--themeColor);
}
.swiper-pagination {
  bottom: -55px !important;
}

.swiper-pagination-bullet {
  width: 15px!important;
  height: 15px!important;
  margin: 0 4px!important;
  background: var(--text)!important;
  border: 1px solid var(--text)!important;
  border-radius: 0!important;
  opacity: 1!important;
  transition: background 0.3s ease, box-shadow 0.3s ease!important;
}

.swiper-pagination-bullet-active {
  background: var(--themeColor)!important;
  border: 1px solid var(--themeColor)!important;
  box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, 0.2)!important;
  -webkit-box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, 0.2)!important;
  -moz-box-shadow: 0px 40px 32px -26px rgba(0, 0, 0, 0.2)!important;
}

/*********    Input Fields     ***********/
.field-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px 0;
}

.input-field {
  padding: 10px 15px;
  border: none;
  outline: none;
  background-color: var(--themeColor);
  border: 1px solid var(--themeColor);
  caret-color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--inputBg) inset !important;
  -webkit-text-fill-color: var(--white) !important;
}

.error-message {
  font-family: var(--arRegular);
  border: 1px solid #ff00006b;
  color: white;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: #ff00006b;
  margin-bottom: 0;
}

.moveOnWrapper {
  background-image: url(../images/png/moveOnSectionBg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.html-box {
  color: var(--white);
}

.spinner {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid;
  border-color: var(--themeColor) transparent;
  animation: spin 1s infinite ease-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.social-link {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0px;
  margin: auto;
  display: flex;
  justify-content: end;
  gap: 15px;
  z-index: 1000;
  height: 40px;
  flex-wrap: wrap;
  width: 50px;
  background-color: var(--themeColor);

  & svg {
    width: 24px;
    height: 24px;
    margin-top: .5rem;
    margin-right: .76rem;
    cursor: pointer;
  }
}

.custom-spinner.spinner-border {
  width: 2.6rem;
  height: 2.6rem;
  border-top-color: var(--themeColor);
  color: var(--themeColor);
  /* Optional: This will color the text if any */
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
//   width: 34px !important;
//   height: 34px !important;
//   background-color: var(--darkColor);
}

.social-icon svg {
  width: 22px;
  height: 22px;
  [stroke] {
    stroke: var(--white);
  }
  [fill] {
    fill: var(--white);
  }
}
.innerPagesBg{
  background-image: url(../images/png/pagesBg.jpg);
  background-repeat: no-repeat;
  background-position: 0 -80px;
  background-size: 100% 120%;
}
/***************** RTL DIrection*************************/

html[dir="rtl"] {
  position: relative !important;
  width: 100%;
  // overflow-x: hidden;
}

body.rtl {
  // position: relative !important;
  font-family: var(--arRegular);

  // font-style: normal;
  // font-size: 20px;
  .headerBtn,
  .cntct-btn span {
    direction: ltr;
  }

  .contactWrapper svg,
  .cntct-btn svg {
    transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    -moz-transform: scaleX(-1) !important;
    -ms-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
  }

  .product-dtl-section .slick-next,
  .product-dtl-section .slick-prev {
    transform: translate(0, -50%) scaleX(-1) !important;
    -webkit-transform: translate(0, -50%) scaleX(-1) !important;
    -moz-transform: translate(0, -50%) scaleX(-1) !important;
    -ms-transform: translate(0, -50%) scaleX(-1) !important;
    -o-transform: translate(0, -50%) scaleX(-1) !important;
  }

  .bannerHeading,
  .sub-heading,
  .main-heading,
  .moveSubHeading,
  .moveMainHeading {
    font-family: var(--arBlack) !important;
  }

  .change-Btn span,
  .commonTxt,
  .item-box-text,
  .footerAboutTxt,
  .footerLinksCol a,
  .footer-op {
    font-family: var(--arRegular) !important;
  }

  button span,
  .slideTitle,
  .item-box-heading {
    font-family: var(--arBoldSans) !important;
  }

  .nav-bar-list a,
  .nav-bar-list span,
  .footer-list a,
  .field-box label,
  .aboutImgCol .aboutImgContainer .aboutImgTxt,
  .nav-bar-list a.active,
  .nav-bar-list span.active,
  .footer-heading,
  .footer-op-company,
  .fowl.footer-op {
    font-family: var(--arBold) !important;
  }

  .change-Btn span,
  .headerBtn span {
    font-family: var(--regular) !important;
  }

  .change-Btn svg {
    margin-right: 6px;
    margin-left: 0;
  }

  .bannerContentWrapper .banner-content {
    width: 850px;
  }

  .bannerContentWrapper .caption-sub-heading {
    font-size: 46px;
  }

  .bannerContentWrapper .caption-heading-main {
    font-size: 75px;
  }

  .contactWrapper span {
    font-size: 20px;
  }

  .bannerContentWrapper .banner-content .bannerManEn {
    display: none;
  }

  .bannerContentWrapper .banner-content .bannerManAr {
    display: block;
  }

  .sub-heading {
    font-size: 22px;
  }
  .banner {
    background-image: url("../images/png/bannerBg.jpg");
  }
  .commonTxt {
    line-height: 35px;
  }

  .aboutContentCol {
    padding-left: 90px;
    padding-right: 30px;
  }

  .aboutImgCol .aboutImgContainer .aboutImgTxt {
    font-size: 22px;
  }

  .aboutContentCol {
    padding-left: 40px;
  }

  .aboutStripe {
    right: 50%;
    left: auto;
    transform: translateX(50%);
  }

  .aboutStripeEn {
    display: none;
  }

  .aboutStripeAr {
    display: block;
  }

  .slideTitle {
    font-size: 30px;
    line-height: 40px;
  }

  .slide-item {
    direction: rtl;
  }

  .moveOnWrapper {
    padding: 80px 740px 85px 20px;
  }

  .moveSubHeading {
    font-size: 30px;
  }

  .moveMainHeading {
    font-size: 60px;
    line-height: 80px;
  }

  .footerCol1 {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: 30px;
  }

  .footerCol2 {
    padding-right: 80px;
    padding-left: 40px;
  }

  .footerCol4 {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-right: 40px;
  }

  .social-link {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: unset;
    margin: auto;
    display: flex;
    justify-content: end;
    gap: 15px;
    z-index: 1000;
    height: 40px;
    flex-wrap: wrap;
    width: 50px;
    background-color: var(--themeColor);

    & svg {
      width: 24px;
      height: 24px;
      margin-top: .5rem;
      margin-right: 0;
      margin-left: .76rem;
      cursor: pointer;
    }
  }

  .whatsapp {
    right: unset;
    left: 0;
  }

}



/* .rtl .header-btn span,
.rtl .choice-content,
.rtl .contact-text,
.rtl .react-images__positioner {
    direction: ltr;
} */