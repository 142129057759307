@font-face {
	font-family: "HeadingNow-82Light";
	src: url("../fonts/HeadingNow-82Light.woff2") format("woff2"),
		url("../fonts/HeadingNow-82Light.woff") format("woff"),
		url("../fonts/HeadingNow-82Light.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'HeadingNow-73Book';
	src: url('../fonts/HeadingNow-73Book.woff2') format('woff2'),
	  url('../fonts/HeadingNow-73Book.woff') format('woff'),
	  url('../fonts/HeadingNow-73Book.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'HeadingNow-74Regular';
	src: url('../fonts/HeadingNow-74Regular.woff2') format('woff2'),
	  url('../fonts/HeadingNow-74Regular.woff') format('woff'),
	  url('../fonts/HeadingNow-74Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'HeadingNow-75Medium';
	src: url('../fonts/HeadingNow-75Medium.woff2') format('woff2'),
	  url('../fonts/HeadingNow-75Medium.woff') format('woff'),
	  url('../fonts/HeadingNow-75Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "HeadingNow-85Medium";
	src: url("../fonts/HeadingNow-85Medium.woff2") format("woff2"),
		url("../fonts/HeadingNow-85Medium.woff") format("woff"),
		url("../fonts/HeadingNow-85Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "HeadingNow-76Bold";
	src: url("../fonts/HeadingNow-76Bold.woff2") format("woff2"),
		url("../fonts/HeadingNow-76Bold.woff") format("woff"),
		url("../fonts/HeadingNow-76Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "HeadingNow-86Bold";
	src: url("../fonts/HeadingNow-86Bold.woff2") format("woff2"),
		url("../fonts/HeadingNow-86Bold.woff") format("woff"),
		url("../fonts/HeadingNow-86Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

/****************Arabic Fonts***************/


@font-face {
    font-family: "Swissra Bold";
    src: url("../fonts/arabic/swissra-bold.eot");
    src: url("../fonts/arabic/swissra-bold.eot?#iefix")format("embedded-opentype"),
    url("../fonts/arabic/swissra-bold.woff2")format("woff2"),
    url("../fonts/arabic/swissra-bold.woff")format("woff"),
    url("../fonts/arabic/swissra-bold.ttf")format("truetype"),
    url("../fonts/arabic/swissra-bold.svg#Swissra Bold")format("svg");
}
@font-face {
    font-family: "Swissra Black";
    src: url("../fonts/arabic/swissra-black.eot");
    src: url("../fonts/arabic/swissra-black.eot?#iefix")format("embedded-opentype"),
    url("../fonts/arabic/swissra-black.woff2")format("woff2"),
    url("../fonts/arabic/swissra-black.woff")format("woff"),
    url("../fonts/arabic/swissra-black.ttf")format("truetype"),
    url("../fonts/arabic/swissra-black.svg#Swissra Black")format("svg");
}

@font-face {
	font-family: "TheSans";
	src: url("../fonts/arabic/TheSans-Plain.woff2") format("woff2"),
		url("../fonts/arabic/TheSans-Plain.woff") format("woff"),
		url("../fonts/arabic/TheSans-Plain.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
    font-family: 'TheSansBold';
    src: url('../fonts/arabic/TheSans-Bold.woff2') format('woff2'),
        url('../fonts/arabic/TheSans-Bold.woff') format('woff'),
        url('../fonts/arabic/TheSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

:root {
	--light: "HeadingNow-82Light";
	--book: "HeadingNow-73Book";
	--regular: "HeadingNow-74Regular";
	--lightMedium: "HeadingNow-75Medium";
	--medium: "HeadingNow-85Medium";
	--bold1: "HeadingNow-76Bold";
	--bold2: "HeadingNow-86Bold";

	--arBold: "Swissra Bold";
	--arBlack: "Swissra Black";
	
	--arRegular: "TheSans";
	--arBoldSans: "TheSansBold";
	
}
