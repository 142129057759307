:root {
    --themeColor: #26CD9F;
    --darkColor: #12233d;
    --darkColorHover: #354259;
    --white: #FFFFFF;
    --text: #88A5CD;
    --text2: #478E98;
    --border: #126951;
    --hoverBg: #11bb8b;
    --bgSecondary: #123D43;
    --textSecondary: #7AB5AF;
    --borderColor: #274571;
    --secBorderColor: #136750;
    --inputBg: #152844;
    --textPalceholder: #415C82;
    --darkColor2: #0F1F38;
    --darkColor3: #162945;
    --dangerColor: #CD263C;
    --primaryBg: #F4FFFC;
    --primaryBg2: #e6fff8;
    --darkGradient: radial-gradient(rgba(17, 66, 68, 1) 0%, rgba(18, 35, 61, 1) 100%);
    --darkGradientStraight: linear-gradient(90deg, rgba(17, 66, 68, 1) 0%, rgba(18, 35, 61,1) 100%);
    --darkGradientBlend: linear-gradient(180deg, rgba(18, 35, 61, .0) 0%, rgba(18, 35, 61, 1) 100%);
    --darkGradientBlend2: linear-gradient(180deg, rgba(9, 40, 63, 1) 0%, rgb(18, 35, 61) 100%, rgb(18, 35, 61) 100%);
    --darkGradientBlendReverse: linear-gradient(180deg, rgba(18, 35, 61, 1) 100%, rgba(18, 35, 61, .0) 0%);
    // --lightGradient: radial-gradient(90deg, rgba(210, 249, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
}