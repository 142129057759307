@media (max-width: 1399px) {
  /* .trainer-img {
    width: 80% !important;
  } */

  // .nav-bar-list {
  //   gap: 5px !important;
  // }

  /* .Banner-board {
    position: relative;
    right: 0px;
    top: 35px;
  }

  .choice-content > div {
    width: 38% !important;
  }

  .choice-1 > div {
    left: 10px !important;
  }

  .choice-2 > div {
    right: 10px !important;
  }

  .choice-3 > div {
    left: 10px !important;
    bottom: 55px;
  }

  .choice-4 > div {
    right: 10px !important;
    bottom: 55px;
  }

  .star-1 {
    left: 270px;
  } */
}

@media (max-width: 1199px) {
  .whtLngWrapper {
    gap: 5px !important;
  }

  .nav-bar-list {
    gap: 20px !important;
  }

  .logo svg {
    width: 100px !important;
  }

  /* .logo svg {
    width: 70px !important;
  }

  .Banner-board {
    position: relative;
    right: 0px;
    top: 35px;
  }

  header .Social-links {
    display: none !important;
  }

  footer .Social-links {
    display: flex !important;
  }

  .video-react-controls-enabled {
    height: 320px !important;
  }

  .trainer-img {
    width: 100% !important;
  }

  p {
    font-size: 13px;
  }

  .tainer-content-list {
    padding: 0 6px;

    & > span {
      width: 45px;
    }
  }

  // .banner-content {
  //   left: 100px !important;
  //   bottom: 120px !important;
  // }

  .choice-content > div {
    width: 37% !important;

    & .main-heading {
      font-size: 28px !important;
    }
  }

  .choice-1 > div {
    left: 0 !important;
  }

  .choice-3 > div {
    left: 0px !important;
    bottom: 40px !important;
  }

  .choice-2 > div {
    right: 0 !important;
  }

  .choice-4 > div {
    right: 0px !important;
    bottom: 10px !important;
  }

  .choice-5 > div {
    bottom: -90px !important;
  }

  .slider-bg .border-image > span {
    bottom: -150px !important;
  }

  .service-vector {
    position: relative;
    bottom: 50px !important;
  }

  // .footer-content>svg {
  //   width: 150px !important;
  // }

  .footer-logo svg {
    width: 70px !important;
  }

  .star-1 {
    left: 150px;
  } */
}

@media (max-width: 991px) {
  /* .social-link {
    display: none;
  } */
  .nav-bar-list {
    display: none !important;
  }

  .toggleBtn {
    margin: 0 5px;
    display: block !important;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }

  .toggleBtn svg {
    color: var(--darkColor);
  }

  .showToggle {
    position: absolute !important;
    top: 79px;
    left: 0;
    background: var(--primaryBg2) !important;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    color: var(--white) !important;
    width: 100% !important;
    display: block !important;
    box-shadow: 0px 5px 15px #dbdbdbd6;
  }
  .sticky .showToggle {
    top: 75px;
  }

  .showToggle div {
    border-bottom: 1px solid var(--darkColor);
    outline: none;
    width: 100%;
    padding: 0;
  }
  .showToggle div:first-child {
    border-top: 1px solid var(--darkColor);
  }
  .showToggle div a {
    display: block;
    padding: 10px 15px;
  }

  .showToggle a:hover {
    color: var(--themeColor) !important;
    -webkit-text-fill-color: var(--themeColor) !important;
  }

  .showToggle .active {
    color: var(--themeColor) !important;
    -webkit-text-fill-color: var(--themeColor) !important;
    
  }

  .showToggle .nav-bar-list a {
    // color: var(--themeColor);
    background: none !important;
  }

  .nav-bar-list a.active::after,
  .nav-bar-list a.active::before,
  .nav-bar-list a:hover::after,
  .nav-bar-list a:hover::before {
    background: none !important;
  }

  .sticky.py-2 {
    transition: all 0.4s ease-in-out;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  .headerBtn {
    height: 42px !important;
    padding: 0px 10px !important;
  }

  .change-Btn {
    height: 42px !important;
    font-size: 14px !important;
  }

  .sub-heading {
    text-align: center;
    justify-content: center;
  }

  .main-heading {
    text-align: center;
    justify-content: center;
  }
  .commonTxt {
    text-align: center;
    justify-content: center;
  }
  .centerBtn {
    width: 100% !important;
    text-align: center;
    justify-content: center;
  }

  // nenu-responsive end

  /* .layers {
    height: auto !important;
    min-height: 550px !important;
    width: 100%;
  }

  // .layer-mid-x-2 {
  //   position: relative;
  //   left: -210px;
  // }

  // .text-styled {
  //   position: relative;
  //   right: 9px !important;
  //   top: 15px !important;
  //   font-size: 200px !important;
  // }
  .banner-content {
    position: relative;
    left: 0px !important;
    bottom: 80px !important;
  }

  .Banner-board {
    position: relative;
    right: 10px !important;
    top: 0px !important;
  }

  // .caption {
  //   font-size: 25px !important;
  // }

  .about-content .w-50 {
    width: 85% !important;
  }

  .about-content {
    width: 100% !important;
  }

  .about-house {
    height: 230px !important;
    width: 250px !important;
    right: -220px !important;
  }

  .house-bird {
    right: 0px !important;
    top: 250px !important;
    width: 190px !important;
  }

  .about-content {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    gap: 40px !important;
  }

  .service-vector {
    width: 300px !important;
    bottom: 0 !important;
  }

  #trainer > .container > .align-items-center {
    flex-direction: column-reverse;
  }

  .border-image > span {
    bottom: -75px !important;
  }

  .trainer-col {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .row-gap {
    margin-top: 60px;
  }

  .heading-main {
    font-size: 50px !important;
  }

  .heading-mini {
    font-size: 20px !important;
  }

  .bach-img {
    display: none !important;
  }

  .bach-logo {
    text-align: end;
  }

  .achived-content-wrapper {
    &.sub-heading {
      font-size: 30px !important;
    }

    & > .heading-main {
      font-size: 54px !important;
    }

    & > .heading-mini {
      font-size: 36px !important;
    }
  }

  .choice-ball {
    display: none !important;
  }

  .choice-content > div {
    position: relative !important;
    width: 100% !important;
    bottom: unset !important;
  }

  .choice-1 > div,
  .choice-3 > div {
    flex-direction: row !important;
    text-align: justify !important;
  }

  .choice-4 > div {
    bottom: auto !important;
  }

  .choice-5 {
    & > div {
      flex-direction: row !important;
      text-align: justify !important;
      justify-content: center !important;
      align-items: baseline !important;

      & > .choice-img-choice-5 {
        width: 140px;
        text-align: center;
        top: 10px;
        position: relative;
      }
    }
  }

  .chosse-bg {
    padding: 60px 0 20px;
  }

  .upper-grid {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    gap: 15px;
    margin-top: 30px;

    & > div {
      width: 50%;

      & > .gallery-items {
        width: 100%;
        height: 300px;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px !important;
        }
      }
    }
  }

  .gallery-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-template-rows: repeat(2, 160px);

    & .gallery-img-wraping {
      & img {
        border-radius: 10px !important;
      }
    }
  }

  .partner-grid {
    grid-template-columns: repeat(4, 1fr) !important;
    grid-template-rows: repeat(4, 130px) !important;
  }

  .containerContact {
    padding-top: 20px;
  }

  .contact-box {
    padding-bottom: 30px;
  }

  iframe {
    width: 100% !important;
  }

  .footer-content {
    flex-direction: column !important;
    gap: 10px !important;
  }

  .border-top-line {
    padding: 15px 0 0 !important;
    bottom: 15px !important;
  }

  .contact-Us {
    order: 1 !important;
  }

  .contect-with-you {
    position: relative !important;
    z-index: 1;
  }

  .star-1 {
    width: 450px;
    left: 100px;
  } */
}

@media (max-width: 768px) {
  /* .about-content {
    width: 100% !important;
  }

  .caption-sub-heading {
    font-size: 18px !important;
  }

  .caption-heading-main {
    font-size: 24px !important;
  }

  .caption-sm-heading {
    font-size: 28px !important;
  }

  .heading-main {
    font-size: 44px !important;
  }

  .heading-mini {
    font-size: 18px !important;
  }

  .Banner-board {
    right: 0px !important;
    top: -30px !important;
  }

  .house-bird {
    width: 150px !important;
  }

  .trainer-img {
    width: 30% !important;
  }

  .tainer-content-list > span {
    width: 20px;
  }

  .bach-logo {
    text-align: center;
  }

  .border-image > span {
    bottom: -55px !important;
  }

  .choice-5 > .choice-img-choice-5 {
    width: 110px;
  }

  .slider-bg .border-image > span {
    bottom: -130px !important;
  }

  .gallery-grid {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-template-rows: repeat(1, 160px) !important;
    gap: 20px !important;
  }

  .img-item-7 {
    position: relative;
    grid-column: 1/5 !important;
    grid-row: 1/3 !important;

    &::before {
      content: "+4";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #00000054;
      z-index: 1;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      color: wheat;
      cursor: pointer;
    }
  }

  .partner-grid {
    grid-template-columns: repeat(3, 1fr) !important;
    grid-template-rows: repeat(6, 130px) !important;
  }

  .footer-list {
    display: none !important;
  }

  .footer-revised {
    text-align: center !important;
  }

  .star-1 {
    width: 350px;
    left: 100px;
  } */
}

@media (max-width: 575px) {
  .headerBtnWrapper {
    display: none !important;
  }

  .whtLngWrapper {
    gap: 3px !important;
  }
  .sub-heading {
    font-size: 20px !important;
  }
  .main-heading {
    font-size: 30px !important;
  }
  .custom-col {
    flex: 0 0 auto;
    width: 50%;
  }
  /* .nav-bar button,
  .about-social-link {
    display: none !important;
  }

  .layers {
    height: auto !important;
    min-height: 500px !important;
    width: 100%;
  }

  .about-vector {
    width: 70px !important;
  }

  .caption-sub-heading {
    font-size: 16px !important;
  }

  .heading-main {
    font-size: 40px !important;
  }

  .caption-heading-main {
    font-size: 21px !important;
  }

  .caption-sm-heading {
    font-size: 24px !important;
  }

  p {
    font-size: 14px !important;
  }

  .achived-content-wrapper > .heading-mini {
    font-size: 26px !important;
  }

  .achived-content-wrapper > .heading-main {
    font-size: 50px !important;
  }

  .about-house {
    display: none !important;
  }

  button {
    font-size: 12px !important;
  }

  .banner-content {
    position: relative;
    left: 30px !important;
    bottom: 80px !important;
  }

  .Banner-board > div {
    width: 180px !important;
    height: 145px !important;
  }

  .Banner-board > div::before {
    top: 8px;
    width: 150px;
    height: 136px;
  }

  .Banner-board > div > button {
    width: 130px !important;
    left: 10px !important;
    top: 14px !important;
  }

  .border-image > span {
    bottom: -35px !important;
  }

  .about-content {
    padding-top: 100px !important;
    padding-bottom: 70px !important;
  }

  .achived-content-wrapper {
    justify-content: center !important;
    align-items: center !important;
  }

  #chooseUs .heading-main {
    text-align: center !important;
  }

  #trainer > .container > .align-items-center {
    flex-direction: column-reverse;
    align-items: center !important;
    justify-content: center !important;
  }

  .trainer-col {
    position: relative;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    bottom: 50px !important;
  }

  .service-content {
    text-align: center;
  }

  .choice-wrapper {
    position: relative;
    padding: 0 15px 50px !important;
  }

  .slider-bg .border-image > span {
    bottom: -50px !important;
  }

  .slider-bg {
    padding: 20px 0 30px;
  }

  .gallery-bg {
    padding: 60px 0 0 !important;
  }

  .gallery-content {
    text-align: justify;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .client-content {
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
  }

  .client-bg {
    padding: 50px 0;
  }

  .containerContact {
    padding-top: 30px !important;
  }

  .row-gap {
    row-gap: 30px;
  }

  .footer-revised {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .house-bird {
    display: none;
  }

  .loading-btn {
    top: 50% !important;
    height: 9% !important;
    width: 100% !important;
  }

  .about-animate {
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // .choice-content .main-heading {
  //   text-align: left;
  // }

  .contect-with-us {
    position: relative;
    z-index: 1 !important;
  }

  .Banner-board {
    display: none !important;
  } */
}

@media (max-width: 480px) {
  /* .img-item-8 {
    grid-column: 6/8 !important;
    grid-row: 1/3 !important;
  }

  .img-item-7 {
    grid-column: 1/6 !important;
    grid-row: 1/3 !important;
  }

  .footer-revised {
    text-align: center;
  }

  .whatsapp,
  .change-Btn {
    width: 60px !important;
  }

  choice-content .main-heading {
    font-size: 24px !important;
    text-align: left !important;
  }

  .partner-grid {
    grid-template-columns: repeat(2, 1fr) !important;
    grid-template-rows: repeat(8, 130px) !important;
  }

  .contact-info {
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
    text-align: justify !important;
  }

  .common-space {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .star-1 {
    width: 300px;
    left: 100px;
    top: 180px;
  } */
}

@media (max-width: 420px) {
  /* .star-1 {
    width: 300px;
    left: 30px;
    top: 180px;
  }

  .trainer-img {
    width: 80% !important;
  }

  .border-image > span {
    bottom: -15px !important;
  }

  .choice-5 > .choice-img-choice-5 {
    width: 190px !important;
  }

  .banner-content {
    left: 0px !important;
    bottom: 80px !important;
  }

  .Banner-board > div {
    width: 160px !important;
    height: 135px !important;
  }

  .btn-text {
    padding: 8px 4px !important;
  } */
}

@media (max-width: 380px) {
}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {
  .moveOnWrapper {
    padding: 80px 670px 85px 20px;
  }
}

@media (max-width: 1199px) {
  body.rtl {
    .slideTitle {
      font-size: 25px;
      line-height: 35px;
    }
    .moveOnWrapper {
      padding: 80px 590px 85px 20px;
      .moveMainHeading {
        font-size: 55px;
      }
    }
    .footerCol2 {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}

@media (max-width: 991px) {
  body.rtl {
    .bannerContentWrapper {
      .caption-sub-heading {
        font-size: 40px;
      }

      .caption-heading-main {
        font-size: 65px;
        line-height: 1.5;
      }
    }
    .moveOnWrapper {
      padding: 30px 410px 45px 10px;
      .moveMainHeading {
        font-size: 42px;
      }
      .moveSubHeading {
        font-size: 25px;
      }
    }
  }
}

@media (max-width: 767px) {
  .package-card {
    height: 350px !important;
    .pkg-name {
      font-size: 16px;
    }
  }
  body.rtl {
    .bannerContentWrapper {
      .caption-sub-heading {
        font-size: 30px;
      }

      .caption-heading-main {
        font-size: 50px;
      }
    }

    .contactWrapper span {
      font-size: 18px;
    }
    .moveOnWrapper {
      padding: 30px 15px 45px 15px;
      .moveMainHeading {
        font-size: 40px;
        line-height: 50px;
      }
    }
    .footerCol4 {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }
  }
}

@media (max-width: 575px) {
  // .whatsapp {
  //   display: block !important;
  // }
  body.rtl {
    .bannerContentWrapper {
      padding-bottom: 60px;

      .banner-content .bannerMan {
        right: auto;
        left: 0;
        bottom: -20px;
      }
    }
    .slideTitle {
      font-size: 22px;
    }
  }
  .social-link {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .common-container {
    padding: 6rem 0 5rem 0;
    max-width: 470px;
  }
}
@media (max-width: 470px) {
  .common-container {
    max-width: 370px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

@media (max-width: 480px) {
  body.rtl {
    .contactWrapper span {
      font-size: 14px;
    }
  }
}

@media (max-width: 380px) {
  body.rtl {
    .bannerContentWrapper {
      .banner-content .bannerMan {
        right: auto;
        left: -32px;
      }
    }
  }
}
